import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, Inline, Text, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import Anchor from '@atlaskit/primitives/anchor';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { fg } from '@confluence/feature-gating';
import { ADMIN_NEW_FEATURES } from '@confluence/named-routes';

import { BANNER_HEIGHT } from './useRegisterNav4PromptBanner';

export const i18n = defineMessages({
	bannerContent: {
		id: 'side-navigation.global-banner.content',
		defaultMessage:
			'The new navigation is now available and will be automatically turned on in the coming weeks. Turn it on for your team now or start with just yourself.',
		description:
			'Banner content to promote the new navigation early and prompt admin users to try before GA.',
	},
	bannerReminderContent: {
		id: 'side-navigation.global-banner-reminder.content',
		defaultMessage:
			'The new navigation will be automatically turned on soon. Turn it on for your team now or start with just yourself.',
		description:
			'Banner content to promote the new navigation and prompt admin users to try before they are automatically opted in.',
	},
	bannerCTA: {
		id: 'side-navigation.global-banner.cta',
		description: 'Link to a page that allows user to sign up for live pages beta',
		defaultMessage: 'Go to settings',
	},
	bannerCloseLabel: {
		id: 'side-navigation.global-banner.close.label',
		description:
			'Accessibility label for close button on the right side of the banner that allows user to dismiss the banner',
		defaultMessage: 'Close',
	},
});

const bannerStyles = xcss({
	padding: 'space.050',
	width: '100%',
	backgroundColor: 'color.background.neutral.bold',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: `${BANNER_HEIGHT}px`,
});

const bannerContentStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
});

export type Nav4PromptBannerComponentProps = {
	onDismiss: () => void;
};

export const Nav4PromptBannerComponent = ({ onDismiss }: Nav4PromptBannerComponentProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'displayed',
					actionSubject: 'banner',
					actionSubjectId: 'nav4PromptBanner',
					attributes: {
						isReminderBanner: fg('confluence_nav4_admin_reminder_banner'),
					},
					source: 'nav4PromptBanner',
				},
			}).fire();
		}
	}, [createAnalyticsEvent]);

	const handleLinkClick = (e) => {
		e.preventDefault();

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'nav4PromptBanner',
				source: 'nav4PromptBanner',
				attributes: {
					isReminderBanner: fg('confluence_nav4_admin_reminder_banner'),
				},
			},
		}).fire();
		window.open(ADMIN_NEW_FEATURES.toUrl(), '_blank');
	};

	return (
		<Box backgroundColor="color.background.neutral.bold" xcss={bannerStyles}>
			<Flex justifyContent="space-between" xcss={bannerContentStyles} alignItems="center">
				<Inline>
					<Text color="color.text.inverse">
						{fg('confluence_nav4_admin_reminder_banner') ? (
							<FormattedMessage {...i18n.bannerReminderContent} />
						) : (
							<FormattedMessage {...i18n.bannerContent} />
						)}
					</Text>
					&nbsp;
					<Anchor
						href={ADMIN_NEW_FEATURES.toUrl()}
						onClick={handleLinkClick}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
						style={{ color: token('color.text.inverse') }}
					>
						<FormattedMessage {...i18n.bannerCTA} />
					</Anchor>
				</Inline>
				<IconButton
					icon={(iconProps) => (
						<CloseIcon {...iconProps} color={token('color.icon.inverse')} spacing="none" />
					)}
					label={<FormattedMessage {...i18n.bannerCloseLabel} />}
					appearance="subtle"
					onClick={onDismiss}
				/>
			</Flex>
		</Box>
	);
};
