import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';

import type { OperationsQueryType } from '@confluence/site-operations';
import { OperationsQuery } from '@confluence/site-operations';
import { ssrBannerManager } from '@confluence/ssr-utilities';
import type { BannerStateContainer } from '@confluence/banners';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { useGetNav4OptIn } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

export const BANNER_HEIGHT = 44;
const BANNER_NAME = 'Nav4PromptBanner';

export const useRegisterNav4PromptBanner = (bannerState: BannerStateContainer | null = null) => {
	const { nav4AdminOptedIn } = useGetNav4OptIn();
	const { userId } = useSessionData();
	const { data, error } = useQuery<OperationsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		OperationsQuery,
		{
			skip: !userId,
		},
	);
	if (error && isUnauthorizedError(error)) {
		markErrorAsHandled(error);
	}

	const isAdmin = data?.siteOperations?.application?.includes('administer');
	// The reminder banner should be displayed before users are a few days away from being forced into nav4,
	// the only distinction between the two banners is the text.
	const storageKey = fg('confluence_nav4_admin_reminder_banner')
		? PERSISTED_KEYS_ON_SERVER.PERSISTED_NAV4_PROMPT_REMINDER_BANNER_FIRST_DISMISSED
		: PERSISTED_KEYS_ON_SERVER.PERSISTED_NAV4_PROMPT_BANNER_FIRST_DISMISSED;
	const [isDismissed, setIsDismissed] = useState<boolean>(localStorage.getItem(storageKey));

	const onDismiss = useCallback(() => {
		localStorage.setItem(storageKey, 'true');
		setIsDismissed(true);
		bannerState?.hide(BANNER_NAME);
	}, [bannerState, storageKey]);

	/**
	 * Conditions to show Nav4PromptBanner
	 * - Do not show if banner has been previously dismissed
	 * - Nav4 GA rollout phase 1
	 * - Nav4 GA phase 2 has not started
	 * - Site admins
	 */
	const shouldRender =
		!isDismissed &&
		isAdmin &&
		!nav4AdminOptedIn &&
		fg('confluence_nav4_ga_phase_1') &&
		!fg('confluence_nav4_ga_phase_2');

	if (shouldRender) {
		bannerState?.show(BANNER_NAME, BANNER_HEIGHT);
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR) {
			ssrBannerManager.registerBanner({
				name: BANNER_NAME,
				height: BANNER_HEIGHT,
			});
		}
	}

	return {
		shouldRender: Boolean(shouldRender),
		onDismiss,
	};
};
