import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { FULL_PAGE_EDITOR_TOOLBAR_HEIGHT } from '@atlaskit/editor-shared-styles';

import { MAXIMUM_TWO_LINE_TOOLBAR_BREAKPOINT } from '@confluence/editor-common-styles';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Indicator = styled.div<{
	show: boolean;
	viewMode?: boolean;
}>({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	zIndex: 1,
	justifyContent: 'center',
	alignItems: 'center',
	// We are holding on converting FULL_PAGE_EDITOR_TOOLBAR_HEIGHT until active content topper development is complete
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: (props) => (props.viewMode ? 0 : FULL_PAGE_EDITOR_TOOLBAR_HEIGHT()),
	left: token('space.0'),
	height: token('space.400'),
	backgroundColor: token('color.background.accent.gray.subtlest'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: `scaleY(${(props) => (props.show ? 1 : 0)})`,
	transformOrigin: 'top',
	transition: 'all 500ms ease',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media (max-width: ${MAXIMUM_TWO_LINE_TOOLBAR_BREAKPOINT}px)`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: (props) => (props.viewMode ? 0 : FULL_PAGE_EDITOR_TOOLBAR_HEIGHT()),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StatusIndicator = styled(Indicator)<{
	show: boolean;
	showOnlineBackground: boolean;
}>({
	zIndex: 700,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.show && props.showOnlineBackground
			? token('color.background.success')
			: token('color.background.accent.gray.subtlest'),
});

// TODO: Figure out why @compiled is having trouble with the @media lambda function below.
// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledText = styled.span<{ mediaMaxWidth?: string }>`
	font-family: inherit;
	font-weight: ${token('font.weight.bold')};
	font-size: 12px;
	color: ${token('color.text.subtlest')};
	margin-left: ${token('space.100')};
	white-space: nowrap;
	overflow: hidden;
	@media (max-width: ${(props) => props.mediaMaxWidth}) {
		width: 80vw;
	}
`;
StyledText.defaultProps = {
	mediaMaxWidth: '650px',
};

export const FadingSpan = (props) => <FadingSpanInternal aria-hidden={!props?.show} {...props} />;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FadingSpanInternal = styled.span<{ show: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.show ? 1 : 0),
	transition: 'opacity 0.5s',
	position: 'absolute',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EllipsisContainer = styled.span({
	display: 'flex',
	alignItems: 'flex-end',
	height: token('space.100'),
	marginLeft: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AnimatedDot = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes wave': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%, 60%, 100%': {
			transform: 'initial',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'30%': {
			transform: 'translateY(-1px)',
		},
	},
	display: 'inline-block',
	width: token('space.025'),
	height: token('space.025'),
	borderRadius: '50%',
	marginRight: token('space.025'),
	background: token('color.text.subtlest'),
	animation: 'wave 0.6s linear infinite',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:nth-of-type(2)': {
		animationDelay: '-0.4s',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:nth-of-type(3)': {
		animationDelay: '-0.3s',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledRefreshLink = styled.a({
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	color: token('color.text.brand'),
});
