import { LoadableLazy } from '@confluence/loadable';

export const LivePagesInProductPromptGlobalBanner = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-live-pages-in-product-prompt-banner" */
				'../src/in-product-prompt/LivePagesInProductPromptGlobalBannerContainer'
			)
		).LivePagesInProductPromptGlobalBannerContainer,
});
