import React from 'react';
import { styled } from '@compiled/react';

import { BannerSpacer } from '@confluence/banners';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { SuperAdminBanner } from '@confluence/super-admin';
import { PageWasInactiveBanner } from '@confluence/browser-status-indicator';
import { AdminAnnouncementBanner } from '@confluence/admin-announcement-banner/entry-points/AdminAnnouncementBanner';
import { GracePeriodBanner } from '@confluence/trial-components/entry-points/GracePeriodBanner';
import { StorageEnforcementBanner } from '@confluence/storage-enforcement/entry-points/StorageEnforcementBanner';
import { CookiesConsentBanner } from '@confluence/cookies-consent-banner/entry-points/CookiesConsentBanner';
import { UnsupportedBrowserBanner } from '@confluence/unsupported-browser-banner';
import { DevToolsBanner } from '@confluence/dev-tools';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { LivePagesInProductPromptGlobalBanner } from '@confluence/live-pages-features/entry-points/InProductPromptBanner';
import { Nav4PromptBannerLoadable } from '@confluence/side-navigation/entry-points/Nav4PromptBanner';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerPositioner = styled.div({
	position: 'fixed',
	width: '100%',
	top: 0,
	left: 0,
	/* It has to be under all the drawers:
    - recent drawer
    - quick search
    - notification
    - app switcher
    - help menu
    But also ABOVE the page tree navigation.
    201 is an oddly specific number but the only
    one that covers all situations.
  */
	zIndex: 201,
});

type BannerContainerProps = {
	usePortalSpacer?: boolean;
};

const BannerContainerComponent = (props: BannerContainerProps) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<ErrorBoundary attribution={Attribution.ADMIN_EXPERIENCE}>
			<BannerPositioner data-vc="banner-container-component" {...ssrPlaceholderIdProp}>
				{/*---- Add your top-of-page banners here, in the order that they are to be shown (top to bottom) ---*/}
				<Nav4PromptBannerLoadable />
				<LivePagesInProductPromptGlobalBanner />
				<GracePeriodBanner />
				<SuperAdminBanner />
				<PageWasInactiveBanner />
				<StorageEnforcementBanner />
				<AdminAnnouncementBanner />
				<CookiesConsentBanner />
				<UnsupportedBrowserBanner />
				<DevToolsBanner />
				{/*---- End of the banner component list ---*/}
			</BannerPositioner>
			<BannerSpacer {...props} />
		</ErrorBoundary>
	);
};

export const BannerContainer = React.memo(BannerContainerComponent);
